<!-- 我的终端 -->

<template>
  <div class="terminal">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="supermarketValue" placeholder="请搜索商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="schoolValue" placeholder="请搜索校区" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="city" label="地区"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="schoolName" label="校区"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="contactMan" label="负责人"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="contactTel" label="联系方式"></el-table-column>
          <el-table-column show-overflow-tooltip label="操作" header-align="center" align="center" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
              <el-button type="" plain @click="toIM(scope.row)">在线联系</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      supermarketValue: "", // 搜索商超名称
      schoolValue: "", // 搜索校区
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getMyTerminal()
  },
  methods: {
    // 获取我的终端列表
    getMyTerminal() {
      this.loading = true
      this.$axios
        .get(this.$api.getMyTerminal, {
          params: {
            supermarketName: this.supermarketValue || null, // 搜索商超名称
            school: this.schoolValue || null, // 搜索校区
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    toIM(row) {
      // 判断当前环境
      if (process.env.NODE_ENV === "production") {
        window.open(`https://consult.xiaoyuanshenghuowang.com/#/b2b?sid=${this.$store.state.userInfo.supplierId}&ssid=${row.shopId}&type=${1}`) //type类型：1供应商；2商超；3骑手；4商城；5总后台
      } else {
        window.open(`http://192.168.2.6:8080/#/b2b?sid=${this.$store.state.userInfo.supplierId}&ssid=${row.shopId}&type=${1}`) //type类型：1供应商；2商超；3骑手；4商城；5总后台
      }
    },
    handleCheck(row) {
      this.$router.push({ path: "/products/detail/info", query: { shopId: row.shopId } })
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getMyTerminal()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData()
      this.pageSize = e
      this.getMyTerminal()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getMyTerminal()
    },
  },
}
</script>

<style scoped lang="scss">
.terminal {
  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
      width: 220px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
